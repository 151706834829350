import { keys } from 'ramda';

export const FEATURE_NAME = 'patients';

export const CURRENT = 'current';
export const FILTERS = 'filters';
export const HEALTH_RECORD = 'healthRecord';
export const LICENSES = 'licenses';

export const TESTS = 'tests';
export const EYE = 'eye';
export const RIGHT = 'right';
export const LEFT = 'left';
export const SCORE = 'score';
export const COLOR = 'color';
export const OD = 'od';
export const OS = 'os';
export const LAST_TEST = 'lastTest';

export const DRUG = 'drug';

export const ALLEYE_SIGNAL = 'alleyeSignal';
export const FILTER = 'filter';
export const ALLEYE = 'alleyeAlarm';
export const LICENSE = 'license';
export const UNCONNECTED = 'unconnected';
export const PATIENT = 'patient';

export const PATIENT_ID = 'patientId';
export const AUTO_RENEW = 'autorenew';
export const STATUS = 'status';
export const GENDER = 'gender';
export const FIRST_NAME = 'firstname';
export const LAST_NAME = 'lastname';
export const BIRTH = 'birthday';
export const INTERNAL_ID = 'internalID';
export const MOBILE = 'mobile';
export const MOBILE_COUNTRY_CODE = 'mobileCountryCode';
export const LANGUAGE = 'language';
export const CODE = 'code';

export const PURCHASE_TYPE = 'purchaseType';
export const VOLUME_TYPE = 'VOLUME';
export const CARD_TYPE = 'CARD';
export const VOUCHER_TYPE = 'VOUCHER';
export const VOUCHER = 'voucher';

export const VALID = 'valid';
export const DURATION = 'duration';

export const AMD_OD = 'amdOD';
export const AMD_OS = 'amdOS';
export const DME_OD = 'dmeOD';
export const DME_OS = 'dmeOS';
export const CRVO_OD = 'crvoOD';
export const CRVO_OS = 'crvoOS';
export const OTHERS_OD = 'othersOD';
export const OTHERS_OS = 'othersOS';
export const CATARACT_SURGERY_OD = 'cataractSurgeryOD';
export const CATARACT_SURGERY_OS = 'cataractSurgeryOS';
export const DIABETES = 'diabetes';
export const DIABETES_TYPE = 'diabetesType';
export const DIABETES_DURATION = 'diabetesDuration';
export const TREATMENT_PLAN = 'treatmentPlan';
export const PRN = 'prn';
export const TREAT_AND_EXTEND = 'treatandextend';
export const OBSERVE = 'observe';
export const OTHER = 'other';
export const HOSPITAL = 'hospital';
export const APPOINTMENT = 'appointment';

export const AVASTIN = 'avastin';
export const BEOVU = 'beovu';
export const EYLEA = 'eylea';
export const EYLEA_HD = 'eylea hd';
export const LUCENTIS = 'lucentis';
export const OZURDEX = 'ozurdex';
export const VABYSMO = 'vabysmo';
export const BYOOVIZ = 'byooviz';
export const CIMERLI = 'cimerli';
export const ILUVIEN = 'iluvien';
export const OTHERS = 'others';

export const VALUE = 'value';
export const PREV_VALUE = 'prevValue';
export const TYPE_1 = 'type_1';
export const TYPE_2 = 'type_2';
export const MIXED = 'mixed';
export const UNKNOWN = 'unknown';
export const LESS_10 = 'less_10_years';
export const BETWEEN_10_20 = '10_20_years';
export const MORE_20 = 'more_than_20_years';
export const TELEPHONE = 'telephone';
export const VIDEO = 'video';
export const PERSON = 'person';

export const VA = 'va';
export const CRT = 'crt';
export const TREATMENT = 'treatment';
export const UNIT = 'unit';

export const EMOTIONAL_WELL_BEING_SCORE = 'emotionalWellBeingScore';
export const VISUAL_FUNCTIONING_SCORE = 'visualFunctioningScore';

export const DRUG_LIST = {
  [EYLEA_HD]: { name: 'Eylea HD', color: 'var(--color-drug-eyleaHd)' },
  [EYLEA]: { name: 'Eylea', color: 'var(--color-drug-eylea)' },
  [LUCENTIS]: { name: 'Lucentis', color: 'var(--color-drug-lucentis)' },
  [VABYSMO]: { name: 'Vabysmo', color: 'var(--color-drug-vabysmo)' },
  [BEOVU]: { name: 'Beovu', color: 'var(--color-drug-beovu)' },
  [AVASTIN]: { name: 'Avastin', color: 'var(--color-drug-avastin)' },
  [OZURDEX]: { name: 'Ozurdex', color: 'var(--color-drug-ozurdex)' },
  [BYOOVIZ]: { name: 'Byooviz', color: 'var(--color-drug-byooviz)' },
  [CIMERLI]: { name: 'Cimerli', color: 'var(--color-drug-cimerli)' },
  [ILUVIEN]: { name: 'Iluvien', color: 'var(--color-drug-iluvien)' },
  [OTHERS]: { name: 'Others', color: 'var(--color-drug-others)' },
};

const ALL_DRUGS = keys(DRUG_LIST);
export const DRUGS_US = ALL_DRUGS;
export const DRUGS_CH = ALL_DRUGS.filter((drug) => ![AVASTIN, BYOOVIZ, CIMERLI].includes(drug));
export const DRUGS_DEFAULT = ALL_DRUGS.filter((drug) => ![BYOOVIZ, CIMERLI].includes(drug));
